import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule), canActivate: [AuthGuard]
    
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
    
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./sign-up/sign-up.module').then( m => m.SignUpPageModule)
    
  },
  {
    path: 'hub-editor',
    loadChildren: () => import('./hub-editor/hub-editor.module').then( m => m.HubEditorPageModule)
  },
  {
    path: 'person-editor',
    loadChildren: () => import('./person-editor/person-editor.module').then( m => m.PersonEditorPageModule)
  },
  {
    path: 'device-editor',
    loadChildren: () => import('./device-editor/device-editor.module').then( m => m.DeviceEditorPageModule)
  },
  {
    path: 'floor-editor',
    loadChildren: () => import('./floor-editor/floor-editor.module').then( m => m.FloorEditorPageModule)
  },
  {
    path: 'hub-group-editor',
    loadChildren: () => import('./hub-group-editor/hub-group-editor.module').then( m => m.HubGroupEditorPageModule)
  },
  {
    path: 'group-editor',
    loadChildren: () => import('./group-editor/group-editor.module').then( m => m.GroupEditorPageModule)
  },
  {
    path: 'alert-viewer',
    loadChildren: () => import('./alert-viewer/alert-viewer.module').then( m => m.AlertViewerPageModule)
  },
  {
    path: 'twilio-call',
    loadChildren: () => import('./twilio-call/twilio-call.module').then( m => m.TwilioCallPageModule)
  },
  {
    path: 'soft-alert-viewer',
    loadChildren: () => import('./soft-alert-viewer/soft-alert-viewer.module').then( m => m.SoftAlertViewerPageModule)
  },
  {
    path: 'resident-report',
    loadChildren: () => import('./resident-report/resident-report.module').then( m => m.ResidentReportPageModule)
  },
  {
    path: 'organization-report',
    loadChildren: () => import('./organization-report/organization-report.module').then( m => m.OrganizationReportPageModule)
  },
  {
    path: 'group-report',
    loadChildren: () => import('./group-report/group-report.module').then( m => m.GroupReportPageModule)
  },
  {
    path: 'alert-rule-editor',
    loadChildren: () => import('./alert-rule-editor/alert-rule-editor.module').then( m => m.AlertRuleEditorPageModule)
  },
  {
    path: 'worker-editor',
    loadChildren: () => import('./worker-editor/worker-editor.module').then( m => m.WorkerEditorPageModule)
  },
  {
    path: 'hub-report',
    loadChildren: () => import('./hub-report/hub-report.module').then( m => m.HubReportPageModule)
  },
  {
    path: 'sign-up-summary',
    loadChildren: () => import('./sign-up-summary/sign-up-summary.module').then( m => m.SignUpSummaryPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
