import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	public authenticated: boolean = false;
	constructor(private router: Router) {}
	  
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
			let authInfo = {
				authenticated: this.authenticated
		};

		if (!authInfo.authenticated) {
			this.router.navigate(["login"]);
			return false;
		}
		return true;
	}
  
}
