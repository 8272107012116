// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //serviceURL: "http://localhost:5001",
  serviceURL: "https://dev.gentroo.com:25681",
  apiCredentials: "",
  googleKey: "AIzaSyBpEEuAlT83SqpM1feIXId4qr75XTaiyx0",
  TWILIOAPIKEY: "SKe663f5705895c0e8ffdbce2e72e1ec51",
  TWILIO_AUTH_TOKEN: "0d86f1afa9e479abdbe0a3ebc7e149f6",
  TWILIOPUSHCREDENTIAL: "CRc22c38843822196325faabb80c49d22e",
  TWILIOAPPSID: "AP302595f8cda75d7b3382e1292d5062f6",
  TWILIO_ACCOUNT_SID: "AC1b7907a8535670b2d0e55eee6a43d022",
  TWILIOSECRET: "2WHFHXoRDQIaFLn6oJqiX9pMK3sZyp9Q",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
